<template>
  <base-section id="faq">
    <base-section-heading
      color="grey lighten-2"
      title="F.A.Q"
    />

    <v-container>
      <template>
        <v-expansion-panels focusable>
          <v-expansion-panel
            v-for="item in data"
            :key="item.questions"
          >
            <v-expansion-panel-header>{{item.questions}}</v-expansion-panel-header>
            <v-expansion-panel-content class="justify-center">
              {{item.answers}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFAQ',

    data: () => ({
      data: [
        {
          questions: 'Apa Itu Asuransi Syariah?',
          answers: 'Asuransi syariah (ta’min, takaful, atau tadhamun) adalah usaha saling melindungi dan tolong-menolong di antara sejumlah orang/pihak melalui investasi dalam bentuk aset dan/atau tabarru’ yang memberikan pola pengembalian untuk menghadapi resiko tertentu melalui akad (perikatan) yang sesuai dengan syariah. (Fatwa Dewan Syari\'ah Nasional Majelis Ulama Indonesia No. 21/DSN-MUI/X/2001 tentang Pedoman Umum Asuransi Syariah.',
        },
        {
          questions: 'Apa Itu Akad Syariah?',
          answers: 'Akad dalam bahasa Arab berarti “pengikatan antara ujung-ujung sesuatu”. Ikatan disini tidak dibedakan apakah berbentuk fisik atau kiasan. Sedangkan menurut pengertian istilah, akad berarti ikatan antara ijab dan qobul yang diselenggarakan menurut ketentuan syariah dimana terjadi konsekuensi hukum atas sesuatu yang karenanya akad diselenggarakan.<br>Akad syariah adalah akad atau perikatan yang terbebas dari unsur gharar (ketidakjelasan), maisir (judi), riba (bunga), zulmu (penganiayaan), riswah (suap), barang haram, dan maksiat',
        },
        {
          questions: 'Akad Syariah Apa yang Dipakai Takaful Umum?',
          answers: 'Akad yang dipakai di Takaful Umum adalah Wakalah bil Ujrah yaitu pemberian kuasa kepada pengelola sebagai wakil peserta untuk mengelola dana tabarru’ dan/atau kegiatan lain sehubungan dengan pengelolaan dana tersebut yang meliputi namun tidak terbatas pada kegiatan administrasi, pengelolaan dana, pembayaran klaim, underwriting, pengelolaan portofolio risiko, pemasaran dan/atau investasi, sesuai kuasa atau wewenang yang diberikan, dengan imbalan berupa ujrah (fee)',
        },
        {
          questions: 'Bagaimana Cara Pengelolaan Kontribusi dan Investasi di Takaful Umum?',
          answers: 'Omplementasi pengelolaan kontribusi dalam sistem operasional perusahaan asuransi syariah direalisasikan dalam bentuk pemisahan pos laporan keuangan menjadi 2 (dua) bagian yaitu : (1) Laporan Laba/Rugi dan Penghasilan Komprehensif Lain – milik Dana Perusahaan/Pengelola; (2) Laporan Surplus (Defisit) Dana Tabarru’ – milik Dana Peserta. Selanjutnya, masing-masing dana yang terkumpul di kedua pos di atas diinvestasikan ke dalam instrumen investasi yang tidak bertentangan dengan syariah. Apabila dari hasil investasi di sisi pengelola diperolah hasil investasi maka akan menjadi keuntungan (profit) bagi pengelola itu sendiri. Di sisi Dana Tabarru’ pun, jika terdapat hasil investasi, akan menjadi keuntungan yang dapat menambah saldo Dana Tabarru’.',
        },
        {
          questions: 'Apakah Peserta Asuransi Syariah Harus Seorang Muslim ?',
          answers: 'Untuk menjadi peserta Takaful Umum tidak harus seorang muslim karena karena dalam konsep muamalah, tidak ada larangan apa pun dalam transaksi secara ekonomi dan sosial selama dalam koridor mampu menjaga agama, jiwa, akal, keturunan, dan harta. Hanya saja prinsip-prinsip hubungan muamalah itu harus tetap terpenuhi, seperti keadilan, kesetaraan, kejujuran, kepercayaan, dan lain-lain.',
        },
      ],
    }),
  }
</script>
